import { createRouter, createWebHistory } from "vue-router";
// import { useUserStore } from "../store/user";

const routes = [
  {
    path: "/",
    component: () => import("../pages/IndexPage.vue"),
  },
  { path: "/about", component: () => import("../pages/AboutPage.vue") },
  { path: "/contact", component: () => import("../pages/ContactPage.vue") },
  { path: "/service", component: () => import("../pages/ServicePage.vue") },
  { path: "/technology", component: () => import("../pages/TechnologyPage.vue") },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from, next) => {
//   const publicPages = ['/login', '/signup', '/forgot-password'];

// });

export default router;
